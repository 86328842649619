import IoTAPI from "./api/IoTAPI";
import DevAPI from "./api/DevAPI";
import GinIoTAPI from "./api/GinIoTAPI";
import { axiosGet, axiosPatch } from "./api/wrapperAPI";

const get = (url: string, type?: string) => {
  switch (type) {
    case "dev":
      return DevAPI.get("/device" + url);
    case "gin":
      return GinIoTAPI.get("/device" + url);
    default:
      return IoTAPI.get("/device" + url);
  }
};

/**
 * GET /device/{id} 호출
 * @param deviceID {id}에 들어갈 인수
 *
 *
 */
export const getDeviceInfo = (deviceID: string) =>
  axiosGet(get(`/${deviceID}`));

const patch = (url: string, requestBody: Object, type?: string) => {
  switch (type) {
    case "dev":
      return DevAPI.patch("/device" + url, requestBody);
    case "gin":
      return GinIoTAPI.patch("/device" + url, requestBody);
    default:
      return IoTAPI.patch("/device" + url, requestBody);
  }
};

export const patchDevice = (deviceID: string, requestBody: Object) =>
  axiosPatch(patch(`/${deviceID}`, requestBody));

export const patchFeedMakerPower = (deviceID: string, power: boolean) =>
  axiosPatch(
    patch(`/${deviceID}`, {
      property: { feedMaker: { power: power } },
    })
  );

export const patchWaterLevelAuto = (
  deviceID: string,
  outletName: number | string,
  auto: boolean
) =>
  axiosPatch(
    patch(`/${deviceID}`, {
      property: { waterLevel: { [outletName]: { auto: auto } } },
    })
  );

export const patchOutletPower = (
  deviceID: string,
  outletName: number | string,
  power: boolean
) =>
  axiosPatch(
    patch(`/${deviceID}`, {
      property: { outlet: { [outletName]: { power: power } } },
    })
  );

export const patchOutletInterval = (
  deviceID: string,
  outletName: number | string,
  timerInterval: number,
  onOffInterval: number
) =>
  axiosPatch(
    patch(`/${deviceID}`, {
      property: {
        outlet: {
          [outletName]: {
            timerInterval: timerInterval,
            onOffInterval: onOffInterval,
          },
        },
      },
    })
  );

export const patchAirConditioner = (
  deviceID: string,
  propertyName: string,
  propertyValue: number | boolean
) =>
  axiosPatch(
    patch(`/${deviceID}`, {
      property: { airControlStatus: { [propertyName]: propertyValue } },
    })
  );
