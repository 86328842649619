import axios from "axios";

const IoTAPI = axios.create();

export const baseURL = "https://iot-api.sangsang.farm/v1";

// const apiURL = "https://iot-api.sangsang.farm/v1";
// const ginURL = "https://iot-api-jin.sangsang.farm/v1";
// const devURL = "https://iot-dev.sangsang.farm/v1";

IoTAPI.defaults.baseURL = baseURL;

export const setUrl = (url: string) => {
  IoTAPI.defaults.baseURL = url;
};

export default IoTAPI;
