import { useState, useEffect } from 'react';
import { smartFarmTemperatureHumidityAPI } from '../iot-clientside-api';

function useTemperatureHumidityChart(smartFarmID: number) {
  type TemperatureHumidityProps = {
    time: string;
    temperature: number;
    humidity: number;
  };

  const [temperatureHumidity, setTemperatureHumidity] = useState<
    TemperatureHumidityProps[]
  >([]);

  const getChartData = () => {
    let now = new Date();
    let yesterday = new Date();
    yesterday.setDate(now.getDate() - 1);

    smartFarmTemperatureHumidityAPI
      .getChart(smartFarmID, yesterday.toISOString(), now.toISOString())
      .then((resolvedData) => {
        if (resolvedData !== null) {
          setTemperatureHumidity(resolvedData);
        }
      });
  };

  useEffect(() => {
    getChartData();

    const interval = setInterval(getChartData, 60 * 1000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  return temperatureHumidity;
}

export default useTemperatureHumidityChart;
