import React from "react";
import styled from "styled-components";
import TableData from "../../commons/TableData";
import AirConditionerStatus from "./AirConditionerComponent/AirConditionerStatus";
import { DeviceProps } from "../../data/deviceIDs";

const Contents = styled.div`
  width: 100%;
  height: 80%;
  overflow: auto;
`;

const NoDevice = styled.div`
  width: 100%;
  padding: 0.5rem;

  ${(props) => props.theme.mediaQueryMobile} {
    padding: 0;
  }
`;

interface AirConditionerComponentProps {
  deviceIDs?: DeviceProps[];
}

function AirConditionerComponent({
  deviceIDs = [],
}: AirConditionerComponentProps) {
  if (deviceIDs.length === 0) {
    return <NoDevice></NoDevice>;
  } else {
    return (
      <TableData title="에어컨 제어">
        <Contents>
          {deviceIDs.map((deviceID) => {
            return <AirConditionerStatus deviceID={deviceID} />;
          })}
        </Contents>
      </TableData>
    );
  }
}

export default AirConditionerComponent;
