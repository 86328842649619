import { ThemeContext, ThemeProvider } from "styled-components";
import { useReducer, createContext, Dispatch, useContext } from "react";

const standardMobileWidth = 770;

type Theme = {
  device: string;
  screenWidth: number;
  screenHeight: number;
  mediaQueryMobile: string;
};

type Action = {
  type: "RESIZE";
  screen: { screenWidth: number; screenHeight: number };
};

export const ScreenDispatchContext = createContext<
  Dispatch<Action> | undefined
>(undefined);

const reducer = (state: Theme, action: Action) => {
  switch (action.type) {
    case "RESIZE": {
      let tmpDevice = "PC";

      if (action.screen.screenWidth < standardMobileWidth) {
        tmpDevice = "MOBILE";
      }

      return {
        ...state,
        device: tmpDevice,
        screenWidth: action.screen.screenWidth,
        screenHeight: action.screen.screenHeight,
      };
    }
    default:
      return state;
  }
};

export function ScreenContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const getStartDevice = () => {
    const width = window.screen.availWidth;

    if (width < standardMobileWidth) {
      return "MOBILE";
    } else {
      return "PC";
    }
  };

  const [state, dispatch] = useReducer(reducer, {
    device: getStartDevice(),
    screenWidth: window.screen.availWidth,
    screenHeight:
      window.screen.availHeight - (window.outerHeight - window.innerHeight),
    mediaQueryMobile: "@media (max-width: " + standardMobileWidth + "px)",
  });

  return (
    <ScreenDispatchContext.Provider value={dispatch}>
      <ThemeProvider theme={state}>{children}</ThemeProvider>
    </ScreenDispatchContext.Provider>
  );
}

export const useScreenContext = () => {
  const screen = useContext(ThemeContext);
  if (!screen) throw new Error("Screen Context Provider not found !");

  return screen;
};

export const useScreenDispatchContext = () => {
  const dispatch = useContext(ScreenDispatchContext);
  if (!dispatch) throw new Error("Screen Context Provider not found !");

  return dispatch;
};
