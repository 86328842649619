import React from "react";
import styled from "styled-components";
import Box from "./Box";

interface TableDataComponentBlockProps {
  mobileWidth?: number;
  mobileHeight?: number;
  hover?: boolean;
}

const TableDataComponentBlock = styled.div<TableDataComponentBlockProps>`
  width: 100%;
  height: 100%;
  padding: 0.5rem;

  &: ${(props) => (props.hover ? "hover" : "")} {
    background-color: #ffffc5;
  }

  ${(props) => props.theme.mediaQueryMobile} {
    width: ${(props) =>
      props.mobileWidth === -1 ? "100%" : props.mobileWidth + "px"};
    height: ${(props) =>
      props.mobileHeight === -1 ? "100%" : props.mobileHeight + "px"};
    flex-direction: column;
  }
`;

const Title = styled.div`
  width: 100%;
  padding: 0.5rem;
  font-weight: bold;
`;

interface TableDataComponentProps {
  children?: React.ReactNode;
  title?: string;
  mobileWidth?: number;
  mobileHeight?: number;
  hover?: boolean;
  onClick?: () => void;
}

function TableDataComponent({
  children,
  title = "title",
  mobileWidth = -1,
  mobileHeight = -1,
  hover = false,
  onClick,
}: TableDataComponentProps) {
  return (
    <TableDataComponentBlock
      mobileWidth={mobileWidth}
      mobileHeight={mobileHeight}
      hover={hover}
      onClick={onClick}
    >
      <Box width={100} height={100}>
        <Title>{title}</Title>
        {children}
      </Box>
    </TableDataComponentBlock>
  );
}

export default TableDataComponent;
