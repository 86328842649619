import { AxiosResponse } from "axios";

export const axiosGet = async (
  callback: Promise<AxiosResponse<any>>
): Promise<any> => {
  try {
    const response = await callback;

    if (response.status !== 200) {
      return null;
    } else {
      return response.data;
    }
  } catch (e) {
    return null;
  }
};

export const axiosPatch = async (
  callback: Promise<AxiosResponse<any>>
): Promise<any> => {
  try {
    const response = await callback;

    if (response.status !== 200) {
      // alert("요청에 실패하였습니다.");
      throw new Error("Patch request failed");
    } else {
      // alert("요청에 성공하였습니다.");
    }
  } catch (e) {
    // alert("요청에 실패하였습니다.");
    throw new Error("Patch request failed");
  }
};

export const axiosPost = async (
  callback: Promise<AxiosResponse<any>>
): Promise<any> => {
  try {
    const response = await callback;

    if (response.status !== 200) {
      return null;
    } else {
      return response.data;
    }
  } catch (e) {
    return null;
  }
};
