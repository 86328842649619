import React, { useState, Suspense } from "react";
import styled from "styled-components";
import loadable from "@loadable/component";
import TableData from "../../commons/TableData";
import HorizontalBarChart from "./NutrientControllerComponent/HorizontalBarChart";
import { DeviceProps } from "../../data/deviceIDs";
import useWaterLevels from "../../hooks/useWaterLevels";
import { useScreenContext } from "../../contexts/ScreenContext";

const Contents = styled.div`
  width: 100%;
  height: 90%;
  overflow: auto;
`;

const fallback = () => <div>loading...</div>;

const CultivatorModal = loadable(
  () => import("./CultivatorComponent/CultivatorModal")
);

interface CultivatorComponentProps {
  deviceIDs?: DeviceProps[];
}

function CultivatorComponent({ deviceIDs = [] }: CultivatorComponentProps) {
  const [modal, setModal] = useState(false);
  const waterLevels = useWaterLevels(deviceIDs);
  const screen = useScreenContext();

  const handleModal = () => {
    const newState = !modal;
    if (!modal) {
      // NOTE: 왜 !modal 이냐
      window.scrollTo(0, 0);
    }
    setModal(newState);
  };
  return (
    <>
      <TableData
        title="재배기 제어기"
        mobileHeight={deviceIDs.length > 12 ? 700 : 500}
        hover={true}
        onClick={handleModal}
      >
        <Contents>
          <HorizontalBarChart
            chartData={waterLevels}
            width={
              screen.device === "PC"
                ? screen.screenWidth * 0.23
                : screen.screenWidth * 0.88
            }
            height={deviceIDs.length * 45}
          />
        </Contents>
      </TableData>
      {modal && (
        <Suspense fallback={fallback}>
          <CultivatorModal deviceIDs={deviceIDs} onClose={handleModal} />
        </Suspense>
      )}
    </>
  );
}

export default CultivatorComponent;
