export type DeviceProps = {
  name: string;
  id: string;
};

export const nutrientControllers: DeviceProps[] = [
  { name: "1번 양액 제어기", id: "NAB300-0008" },
];

export const cultivators: DeviceProps[] = [
  { name: "1번 재배기", id: "OA100-0035" },
  { name: "2번 재배기", id: "OA100-0036" },
  { name: "3번 재배기", id: "OA100-0037" },
  { name: "4번 재배기", id: "OA100-0038" },
  { name: "5번 재배기", id: "OA100-0039" },
  { name: "6번 재배기", id: "OA100-0040" },
  { name: "7번 재배기", id: "OA100-0041" },
  { name: "8번 재배기", id: "OA100-0042" },
  { name: "9번 재배기", id: "OA100-0043" },
];

export const envs: DeviceProps[] = [
  { name: "1번 환경 측정기", id: "ENV100-0031" },
];

export const acs: DeviceProps[] = [
  { name: "에어컨 제어기", id: "eb9b0d7f597f5f38bfec9k" },
];

export const smartFarmID = 8;
