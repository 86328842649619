import React, { useEffect } from "react";
import styled from "styled-components";
import { Route } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import DashboardPage from "./pages/DashboardPage";
import ToastContainer from "./components/ToastContainer";
import { useScreenDispatchContext } from "./contexts/ScreenContext";
import { ToastContextProvider } from "./contexts/ToastContext";

const AppBlock = styled.div`
  width: 100%;
  height: 100%;
  & * {
    box-sizing: border-box;
  }
`;

function App() {
  const dispatch = useScreenDispatchContext();

  const handleResize = () => {
    const screen = {
      screenWidth: window.screen.availWidth,
      screenHeight:
        window.screen.availHeight - (window.outerHeight - window.innerHeight),
    };

    if (screen.screenHeight > window.screen.availHeight) {
      screen.screenHeight = window.screen.availHeight;
    }

    dispatch({
      type: "RESIZE",
      screen: screen,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };

    // eslint-disable-next-line
  }, []);

  return (
    <AppBlock>
      <ToastContextProvider>
        <ToastContainer />
        <Route path="/" component={LoginPage} exact />
        <Route path="/dashboard" component={DashboardPage} />
      </ToastContextProvider>
    </AppBlock>
  );
}

export default App;
