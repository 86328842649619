import { createContext, Dispatch, useReducer, useContext } from "react"; //

/**
 * NOTE Toast 메세지 타입
 *
 * 1. SUCCESS
 * 2. FAILURE
 *
 */

type ToastProps = {
  id: number;
  title: string;
  description: string;
  toastType: string;
  autoDelete: boolean;
  callback?: () => void;
};

const ToastContext = createContext<ToastProps[] | undefined>(undefined);

type Action =
  | {
      type: "TOAST_ADD";
      title: string;
      description: string;
      toastType: string;
      autoDelete: boolean;
      callback?: () => void;
    }
  | { type: "TOAST_DELETE"; id: number }
  | { type: "TOAST_DELETE_ALL" };

const ToastDispatchContext = createContext<Dispatch<Action> | undefined>(
  undefined
);

const reducer = (state: ToastProps[], action: Action) => {
  switch (action.type) {
    case "TOAST_ADD":
      const newToast: ToastProps = {
        id: 0,
        title: action.title,
        description: action.description,
        toastType: action.toastType,
        autoDelete: action.autoDelete,
        callback: undefined,
      };

      if (typeof action.callback !== "undefined") {
        newToast.callback = action.callback;
      }

      if (state.length !== 0) {
        newToast.id = state[0].id + 1;
      }

      return [newToast, ...state];
    case "TOAST_DELETE":
      return state.filter((toast) => action.id !== toast.id);
    case "TOAST_DELETE_ALL":
      return [];
    default:
      return state;
  }
};

export function ToastContextProvider({
  children,
}: {
  children?: React.ReactNode;
}) {
  const [toastList, dispatch] = useReducer(reducer, []);

  return (
    <ToastDispatchContext.Provider value={dispatch}>
      <ToastContext.Provider value={toastList}>
        {children}
      </ToastContext.Provider>
    </ToastDispatchContext.Provider>
  );
}

export const useToastContext = () => {
  const toastList = useContext(ToastContext);
  if (!toastList) throw new Error("Toast Context Provider not found !");

  return toastList;
};

export const useToastDispatchContext = () => {
  const dispatch = useContext(ToastDispatchContext);
  if (!dispatch) throw new Error("Toast Context Provider not found !");

  return dispatch;
};
