import React from "react";
import styled from "styled-components";
import Button from "../../../commons/Button";
import useAirConditioner from "../../../hooks/useAirConditioner";
import { DeviceProps } from "../../../data/deviceIDs";
import connected from "../../../images/connected.png";
import disconnected from "../../../images/disconnected.png";
import powerOff from "../../../images/off.png";
import powerOn from "../../../images/on.png";

const AirConditionerStatusBlock = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 3rem 0 3rem;
  overflow: auto;
  display: flex;
  flex-direction: column;

  ${(props) => props.theme.mediaQueryMobile} {
    padding: 0 1.5rem 1rem 1.5rem;
  }
`;

const Common = styled.div`
  width: 100%;
  padding-top: 4vh;
  display: flex;
  flex-direction: row;
  align-items: center;

  ${(props) => props.theme.mediaQueryMobile} {
  }
`;

const Connection = styled(Common)`
  padding-top: 2vh;
`;

const ConnectionStatus = styled.img`
  width: 20%;
  padding-right: 1rem;
`;

const PowerStatus = styled.img`
  width: 20%;
`;

const Power = styled(Common)``;

const Mode = styled(Common)``;

const Temperature = styled(Common)``;

const FanSpeed = styled(Common)`
  ${(props) => props.theme.mediaQueryMobile} {
    padding: 4vh 0 4vh 0;
  }
`;

const Tag = styled.div`
  width: 30%;

  ${(props) => props.theme.mediaQueryMobile} {
    width: 40%;
  }
`;

const Data = styled.div`
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;

  ${(props) => props.theme.mediaQueryMobile} {
    width: 60%;
  }
`;

const StyledInput = styled.input`
  width: 70%;
  margin-right: 1rem;

  ${(props) => props.theme.mediaQueryMobile} {
    width: 80%;
    margin-right: 0;
  }
`;

interface AirConditionerStatusProps {
  deviceID?: DeviceProps;
}

function AirConditionerStatus({
  deviceID = { id: "", name: "" },
}: AirConditionerStatusProps) {
  const {
    airConditioner,
    setAirConditioner,
    patchAirConditioner,
  } = useAirConditioner(deviceID.id);

  const handleAirConditioner = (
    propertyName: string,
    propertyValue: number | boolean | string
  ) => {
    if (typeof patchAirConditioner !== "undefined") {
      patchAirConditioner(propertyName, propertyValue);
    }
  };

  const handlePower = () => {
    const nowPower = airConditioner.power;
    let newPower = false;
    switch (nowPower) {
      case "꺼짐":
        newPower = true;
        break;
      case "켜짐":
        newPower = false;
        break;
    }
    handleAirConditioner("power", newPower);
  };

  const handleMode = (mode: number) => {
    handleAirConditioner("mode", mode);
  };

  const mouseTemperature = (e: React.MouseEvent<HTMLInputElement>) => {
    const newTemperature = (e.target as HTMLInputElement).value;
    handleAirConditioner("temperature", newTemperature);
  };

  const touchTemperature = (e: React.TouchEvent<HTMLInputElement>) => {
    const newTemperature = (e.target as HTMLInputElement).value;
    handleAirConditioner("temperature", newTemperature);
  };

  const mouseFanSpeed = (e: React.MouseEvent<HTMLInputElement>) => {
    const newFanSpeed = Number((e.target as HTMLInputElement).value);
    handleAirConditioner("fanSpeed", newFanSpeed);
  };

  const touchFanSpeed = (e: React.TouchEvent<HTMLInputElement>) => {
    const newFanSpeed = Number((e.target as HTMLInputElement).value);
    handleAirConditioner("fanSpeed", newFanSpeed);
  };

  return (
    <AirConditionerStatusBlock>
      <Connection>
        <Tag>연결 상태</Tag>
        <ConnectionStatus
          src={airConditioner.connection ? connected : disconnected}
        />
      </Connection>
      <Power>
        <Tag>전원</Tag>
        <Data>
          <PowerStatus
            src={airConditioner.power === "켜짐" ? powerOn : powerOff}
          />
          <Button onClick={handlePower}>ON / OFF</Button>
        </Data>
      </Power>
      <Mode>
        <Tag>모드</Tag>
        <Data>
          <Button
            power={airConditioner.mode === 0}
            onClick={() => handleMode(0)}
          >
            냉방
          </Button>
          <Button
            power={airConditioner.mode === 1}
            onClick={() => handleMode(1)}
          >
            난방
          </Button>
          <Button
            power={airConditioner.mode === 2}
            onClick={() => handleMode(2)}
          >
            자동
          </Button>
          <Button
            power={airConditioner.mode === 4}
            onClick={() => handleMode(4)}
          >
            제습
          </Button>
        </Data>
      </Mode>
      <Temperature>
        <Tag>온도</Tag>
        <Data>
          {airConditioner.temperature}
          <StyledInput
            type="range"
            value={airConditioner.temperature}
            min={18}
            max={30}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setAirConditioner({
                ...airConditioner,
                temperature: e.target.value,
              });
            }}
            onMouseUp={mouseTemperature}
            onTouchEnd={touchTemperature}
          />
        </Data>
      </Temperature>
      {(airConditioner.mode === 0 || airConditioner.mode === 1) && (
        <FanSpeed>
          <Tag>풍속</Tag>
          <Data>
            {airConditioner.fanSpeed === 0 ? "자동" : airConditioner.fanSpeed}
            <StyledInput
              type="range"
              value={airConditioner.fanSpeed}
              min={0}
              max={3}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setAirConditioner({
                  ...airConditioner,
                  fanSpeed: Number(e.target.value),
                });
              }}
              onMouseUp={mouseFanSpeed}
              onTouchEnd={touchFanSpeed}
            />
          </Data>
        </FanSpeed>
      )}
    </AirConditionerStatusBlock>
  );
}

export default AirConditionerStatus;
