import { useState, useEffect } from 'react';
import { smartFarmCo2API } from '../iot-clientside-api';

function useCO2Chart(smartFarmID: number) {
  type Co2Props = {
    time: string;
    co2: number;
  };

  const [co2, setCo2] = useState<Co2Props[]>([]);

  const getChartData = () => {
    let now = new Date();
    let yesterday = new Date();
    yesterday.setDate(now.getDate() - 1);

    smartFarmCo2API
      .getChart(smartFarmID, yesterday.toISOString(), now.toISOString())
      .then((response) => {
        type Response = {
          createdAt: string;
          updatedAt: string;
          deletedAt: string | null;
          id: string;
          time: string;
          co2: number;
        };
        if (response !== null) {
          const tmp = (response as Response[]).map((element) => {
            return {
              time: element.time,
              co2: element.co2,
            };
          });
          setCo2(tmp);
        }
      });
  };

  useEffect(() => {
    getChartData();

    const interval = setInterval(getChartData, 60 * 1000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  return co2;
}

export default useCO2Chart;
