import { useState, useEffect } from 'react';
import { waterLevelAPI } from '../iot-clientside-api';
import { DeviceProps } from '../data/deviceIDs';

type WaterLevelProps = {
  name: string;
  waterLevel: number;
};

function useWaterLevels(deviceIDs: DeviceProps[]) {
  const [waterLevels, setWaterLevels] = useState<WaterLevelProps[]>([]);

  const promiseElement = (
    deviceID: DeviceProps,
    waterLevelName: number,
    waterLevels: WaterLevelProps[]
  ) => {
    const deviceType = deviceID.id.split('-')[0];
    if (deviceType === 'NAB300') {
      return waterLevelAPI
        .getWaterLevelInfo(deviceID.id, waterLevelName)
        .then((resolvedData) => {
          if (resolvedData !== null) {
            const newWaterLevel = resolvedData.state;

            switch (waterLevelName) {
              case 0:
                waterLevels.push({
                  name: deviceID.name + '-양액',
                  waterLevel: newWaterLevel,
                });
                break;
              case 1:
                waterLevels.push({
                  name: deviceID.name + '-A제',
                  waterLevel: newWaterLevel,
                });
                break;
              case 2:
                waterLevels.push({
                  name: deviceID.name + '-B제',
                  waterLevel: newWaterLevel,
                });
                break;
              default:
                waterLevels.push({
                  name: deviceID.name + '-에러',
                  waterLevel: newWaterLevel,
                });
                break;
            }
          }
        });
    } else {
      return waterLevelAPI
        .getWaterLevelInfo(deviceID.id, waterLevelName)
        .then((resolvedData) => {
          if (resolvedData !== null) {
            const newWaterLevel = resolvedData.state;
            waterLevels.push({
              name: deviceID.name,
              waterLevel: newWaterLevel,
            });
          }
        });
    }
  };

  const getWaterLevels = () => {
    let promiseArr: Promise<any>[] = [];
    let tmp: WaterLevelProps[] = [];

    deviceIDs.forEach((deviceID) => {
      const deviceType = deviceID.id.split('-')[0];
      if (deviceType === 'NAB300') {
        // NAB300 일 때
        promiseArr.push(promiseElement(deviceID, 0, tmp));
        promiseArr.push(promiseElement(deviceID, 1, tmp));
        promiseArr.push(promiseElement(deviceID, 2, tmp));
      } else {
        // OA100 일 때
        promiseArr.push(promiseElement(deviceID, 0, tmp));
      }
    });

    Promise.all(promiseArr).then(() => {
      let result: WaterLevelProps[] = [];

      // NOTE 중첩 forEach문
      // for문으로 바꿀지 고민
      deviceIDs.forEach((deviceID) => {
        let arr: WaterLevelProps[] = [];

        tmp.forEach((element) => {
          // 0번 인덱스는 deviceID.name, 1번 인덱스는 물통 타입(양액, A제, B제)
          const elementNameSplit = element.name.split('-');
          if (elementNameSplit[0] === deviceID.name) {
            let type = elementNameSplit[1];
            switch (type) {
              // NAB300
              case '양액':
                arr[0] = element;
                break;
              case 'A제':
                arr[1] = element;
                break;
              case 'B제':
                arr[2] = element;
                break;
              // OA100
              case undefined:
                arr[0] = element;
                break;
              default:
                break;
            }
          }
          // NOTE: 운영단계에서 위의 수정한 코드가 아무 문제 없으면 삭제함.
          // else if (
          //   element.name.slice(0, element.name.length) === deviceID.name
          // ) {
          //   arr[0] = element;
          // }
        });

        result = result.concat(arr);
      });

      setWaterLevels(result);
    });
  };

  useEffect(() => {
    getWaterLevels();

    const interval = setInterval(getWaterLevels, 1000 * 60);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  return waterLevels;
}

export default useWaterLevels;
