import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useToastDispatchContext } from '../contexts/ToastContext';
import checked from '../images/toast/checked.svg';
import warning from '../images/toast/warning.svg';
import question from '../images/toast/question.svg';

const ToastMessageBlock = styled.div<{ backgroundColor?: string }>`
  width: 400px;
  max-height: 100px;
  padding: 15px;
  border-radius: 3px 3px 3px 3px;
  box-shadow: 0 0 10px #999;
  background-color: ${(props) => props.backgroundColor};
  transition: transform 0.6s ease-in-out;
  animation: toast-in-left 0.7s;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &:hover {
    box-shadow: 0 0 12px #5d5d5d;
  }

  @keyframes toast-in-right {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }

  @keyframes toast-in-left {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }

  ${(props) => props.theme.mediaQueryMobile} {
    width: 360px;
  }
`;

const Img = styled.img`
  width: 30px;
  height: 30px;
  margin-right: 15px;
`;

const Message = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 1.1rem;
  font-weight: bold;

  ${(props) => props.theme.mediaQueryMobile} {
    font-size: 1rem;
  }
`;

const Description = styled.div`
  ${(props) => props.theme.mediaQueryMobile} {
    font-size: 0.9rem;
  }
`;

const Button = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  font-weight: bold;
`;

const AnswerButton = styled(Button)`
  font-size: 1rem;
  padding: 0 5px 0 5px;
  margin: 0 5px 0 5px;
  align-self: flex-end;

  ${(props) => props.theme.mediaQueryMobile} {
    font-size: 0.9rem;
    align-self: center;
  }
`;

const CloseButton = styled(Button)``;

interface ToastMessageProps {
  id?: number;
  title?: string;
  description?: string;
  type?: string;
  autoDelete?: boolean;
  callback?: () => void;
}

function ToastMessage({
  id = 0,
  title = 'default',
  description = 'default',
  type = 'default',
  autoDelete = true,
  callback,
}: ToastMessageProps) {
  const dispatch = useToastDispatchContext();

  const getBackgroundColor = (type: string) => {
    switch (type) {
      case 'SUCCESS':
        return '#5cb85c';
      case 'FAILURE':
        return '#d9534f';
      case 'CONFIRM':
        return '#F2CB61';
      default:
        return 'white';
    }
  };

  const getImg = (type: string) => {
    switch (type) {
      case 'SUCCESS':
        return checked;
      case 'FAILURE':
        return warning;
      case 'CONFIRM':
        return question;
      default:
        return checked;
    }
  };

  const deleteToast = () => dispatch({ type: 'TOAST_DELETE', id: id });

  useEffect(() => {
    if (autoDelete) {
      setTimeout(deleteToast, 1000 * 5);
    }

    // eslint-disable-next-line
  }, []);

  return (
    <ToastMessageBlock backgroundColor={getBackgroundColor(type)}>
      <Message>
        <Img src={getImg(type)} alt="icon" />
        <Text>
          {title && <Title>{title}</Title>}
          {description && <Description>{description}</Description>}
        </Text>
      </Message>
      {callback ? (
        <>
          <AnswerButton
            onClick={() => {
              callback();
              deleteToast();
            }}
          >
            확인
          </AnswerButton>
          <AnswerButton onClick={deleteToast}>취소</AnswerButton>
        </>
      ) : (
        <CloseButton onClick={deleteToast}>X</CloseButton>
      )}
      {/* {!callback && <CloseButton onClick={deleteToast}>X</CloseButton>} */}
    </ToastMessageBlock>
  );
}

export default ToastMessage;
