import { useState, useEffect } from 'react';
import { smartFarmCo2API } from '../iot-clientside-api';

function useCO2Recent(smartFarmID: number) {
  type Co2Props = {
    time: string;
    co2: number;
  };

  const [co2Recent, setCo2Recent] = useState<Co2Props>({
    time: 'default',
    co2: 0,
  });

  const getRecentData = () => {
    smartFarmCo2API.getRecent(smartFarmID).then((response) => {
      if (response !== null) {
        const tmp = { time: response.time, co2: response.co2 };
        setCo2Recent(tmp);
      }
    });
  };

  useEffect(() => {
    getRecentData();

    const interval = setInterval(getRecentData, 1000 * 60);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  return co2Recent;
}

export default useCO2Recent;
