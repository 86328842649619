import { useState, useEffect } from 'react';
import { useToastDispatchContext } from '../contexts/ToastContext';
import { remoteControlAPI } from '../iot-clientside-api';

/**
 * NOTE
 *
 * GOQUAL API에서 받아오는 에어컨 제어 상태 값은
 *
 * power : string
 * temperature : string
 * mode : number
 * fanSpeed : number
 *
 * 라서 useState 에도 같은 타입으로 저장을 했다.
 *
 * PATCH 할 때 보내는 Body 에 들어갈 값은
 *
 * power : boolean
 * temperature : string
 * mode : number
 * fanSpeed : number
 *
 * 이다.
 *
 * 엄청 헷갈리니까 조심할 것.
 */

export type AirConditionerProps = {
  connection: boolean;
  power: string;
  temperature: string;
  mode: number;
  fanSpeed: number;
};

function useAirConditioner(deviceID: string) {
  const toast = useToastDispatchContext();
  const [airConditioner, setAirConditioner] = useState<AirConditionerProps>({
    connection: false,
    power: '꺼짐',
    temperature: '0',
    mode: 0,
    fanSpeed: 0,
  });

  const getAirConditioner = () => {
    remoteControlAPI.getDeviceInfo(deviceID).then((resolvedData) => {
      if (resolvedData !== null) {
        setAirConditioner({
          connection: resolvedData.state,
          ...resolvedData.deviceState,
        });
      }
    });
  };

  const patchAirConditioner = (
    propertyName: string,
    propertyValue: number | boolean | string
  ) => {
    const newAirConditionerStatus = {
      power: airConditioner.power === '켜짐' ? true : false,
      mode: airConditioner.mode,
      temperature: airConditioner.temperature,
      fanSpeed: airConditioner.fanSpeed,
      [propertyName]: propertyValue,
    };
    const toastTitle = '에어컨 제어';

    if (propertyName === 'mode' && propertyValue === 4) {
      newAirConditionerStatus.fanSpeed = 0;
    }

    const toastCallback = () => {
      remoteControlAPI
        .postControlDevice(deviceID, newAirConditionerStatus)
        .then(() => {
          let temperature =  Number(airConditioner.temperature);
          if (temperature < 18) {
            temperature = 18;
          }
          else if (temperature > 30) {
            temperature = 30;
          }

          const onlyValue = { value: `${newAirConditionerStatus.mode === 4
            ? '제습' : '자동'} ${temperature}도` };

          remoteControlAPI.postControlDevice(
            'eb5996d6b8b2f61696fjc3',
            onlyValue
          );
          setAirConditioner({
            ...newAirConditionerStatus,
            connection: airConditioner.connection,
            power: newAirConditionerStatus.power ? '켜짐' : '꺼짐',
          });

          toast({
            type: 'TOAST_ADD',
            title: toastTitle,
            description: '요청에 성공하였습니다.',
            toastType: 'SUCCESS',
            autoDelete: true,
          });
        })
        .catch(() => {
          toast({
            type: 'TOAST_ADD',
            title: toastTitle,
            description: '요청에 실패하였습니다.',
            toastType: 'FAILURE',
            autoDelete: true,
          });
        });
    };

    let toastDiscription = '';

    switch (propertyName) {
      case 'power':
        toastDiscription = newAirConditionerStatus.power
          ? '에어컨을 켜시겠습니까 ?'
          : '에어컨을 끄시겠습니까 ?';
        toast({
          type: 'TOAST_ADD',
          title: toastTitle,
          description: toastDiscription,
          toastType: 'CONFIRM',
          autoDelete: false,
          callback: toastCallback,
        });
        break;
      case 'mode':
        toastDiscription =
          newAirConditionerStatus.mode === 0
            ? '냉방으로 바꾸시겠습니까 ?'
            : newAirConditionerStatus.mode === 1
            ? '난방으로 바꾸시겠습니까 ?'
            : newAirConditionerStatus.mode === 2
            ? '자동으로 바꾸시겠습니까 ?'
            : '제습으로 바꾸시겠습니까 ?';
        toast({
          type: 'TOAST_ADD',
          title: toastTitle,
          description: toastDiscription,
          toastType: 'CONFIRM',
          autoDelete: false,
          callback: toastCallback,
        });
        break;
      default:
        toastCallback();
        break;
    }
  };

  useEffect(() => {
    getAirConditioner();

    const interval = setInterval(getAirConditioner, 1000 * 60);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  return {
    airConditioner,
    setAirConditioner,
    getAirConditioner,
    patchAirConditioner,
  };
}

export default useAirConditioner;
