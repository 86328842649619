import IoTAPI from "./api/IoTAPI";
import DevAPI from "./api/DevAPI";
import GinIoTAPI from "./api/GinIoTAPI";
import { axiosGet } from "./api/wrapperAPI";

const get = (url: string, type?: string) => {
  switch (type) {
    case "dev":
      return DevAPI.get("/waterlevel" + url);
    case "gin":
      return GinIoTAPI.get("/waterlevel" + url);
    default:
      return IoTAPI.get("/waterlevel" + url);
  }
};

export const getWaterLevelInfo = (
  deviceID: string,
  waterLevelName: number | string
) => axiosGet(get(`/${deviceID}/waterlevel-${waterLevelName}`));

export const getWaterLevelOutlet = (deviceID: string) =>
  axiosGet(get(`/outlet/${deviceID}`));
