import IoTAPI from "./api/IoTAPI";
import DevAPI from "./api/DevAPI";
import GinIoTAPI from "./api/GinIoTAPI";
import { axiosGet } from "./api/wrapperAPI";

const get = (url: string, type?: string) => {
  switch (type) {
    case "dev":
      return DevAPI.get("/outlet" + url);
    case "gin":
      return GinIoTAPI.get("/outlet" + url);
    default:
      return IoTAPI.get("/outlet" + url);
  }
};

export const getOutletInfo = (deviceID: string, outletName: number | string) =>
  axiosGet(get(`/${deviceID}/outlet-${outletName}`));
