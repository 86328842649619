import React, { useEffect } from "react";
import styled from "styled-components";
import ToastMessage from "./ToastMessage";
import {
  useToastContext,
  useToastDispatchContext,
} from "../contexts/ToastContext";

const ToastContainerBlock = styled.div`
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 2000;
`;

function ToastContainer() {
  const toastList = useToastContext();
  const toast = useToastDispatchContext();

  useEffect(() => {
    return () => {
      toast({ type: "TOAST_DELETE_ALL" });
    };

    // eslint-disable-next-line
  }, []);

  return (
    <ToastContainerBlock>
      {toastList.map((toast) => (
        <ToastMessage
          key={toast.id}
          id={toast.id}
          title={toast.title}
          description={toast.description}
          type={toast.toastType}
          autoDelete={toast.autoDelete}
          callback={toast.callback}
        />
      ))}
    </ToastContainerBlock>
  );
}

export default ToastContainer;
