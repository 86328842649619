import React, { useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import SideBar from "../components/dashboard/SideBar";
import { isUserInfoExist } from "../lib/checkUserInfo";
import { useToastDispatchContext } from "../contexts/ToastContext";
import DeviceStatusComponent from "../components/dashboard/DeviceStatusComponent";
import NutrientControllerComponent from "../components/dashboard/NutrientControllerComponent";
import CultivatorComponent from "../components/dashboard/CultivatorComponent";
import AirConditionerComponent from "../components/dashboard/AirConditionerComponent";
import ChartGroup from "../components/dashboard/ChartGroup";
import * as deviceIDs from "../data/deviceIDs";

const DashboardPageBlock = styled.div`
  width: 100%;
  height: 100%;
`;

const Contents = styled.div`
  width: ${(props) => props.theme.screenWidth + "px"};
  height: ${(props) => props.theme.screenHeight * 0.94 + "px"};
  display: flex;
  flex-direction: row;
  padding: 0.5rem 0 0.5rem 0;
  ${(props) => props.theme.mediaQueryMobile} {
    height: auto;
    flex-direction: column;
  }
`;

const LeftHalfDiv = styled.div`
  width: 50%;
  height: 100%;
  padding: 0 0 0 0.5rem;
  display: flex;
  flex-direction: column;

  ${(props) => props.theme.mediaQueryMobile} {
    width: 100%;
    height: auto;
    padding: 0;
    border-right: none;
    border-bottom: solid;
    border-width: 0.5px;
  }
`;

const LeftDiv = styled.div`
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: row;

  ${(props) => props.theme.mediaQueryMobile} {
    height: 50%;
    flex-direction: column;
  }
`;

const RightHalfDiv = styled.div`
  width: 50%;
  height: 100%;
  padding: 0 0.5rem 0 0;

  ${(props) => props.theme.mediaQueryMobile} {
    width: 100%;
    height: auto;
    padding: 0;
  }
`;

function DashboardPage() {
  const history = useHistory();
  const toast = useToastDispatchContext();

  const allDeviceIDs = deviceIDs.nutrientControllers
    .concat(deviceIDs.cultivators)
    .concat(deviceIDs.envs);

  useEffect(() => {
    if (isUserInfoExist(sessionStorage) === -1) {
      // alert("로그인이 필요합니다 !");
      toast({
        type: "TOAST_ADD",
        title: "로그인 상태",
        description: "로그인이 필요합니다 !",
        toastType: "FAILURE",
        autoDelete: true,
      });
      history.replace("/");
    }
    // eslint-disable-next-line
  }, []);

  return (
    <DashboardPageBlock>
      <SideBar url="/dashboard" />
      <Contents>
        <LeftHalfDiv>
          <LeftDiv>
            <DeviceStatusComponent deviceIDs={allDeviceIDs} />
            <NutrientControllerComponent
              deviceIDs={deviceIDs.nutrientControllers}
            />
          </LeftDiv>
          <LeftDiv>
            <CultivatorComponent deviceIDs={deviceIDs.cultivators} />
            <AirConditionerComponent deviceIDs={deviceIDs.acs} />
          </LeftDiv>
        </LeftHalfDiv>
        <RightHalfDiv>
          <ChartGroup smartFarmID={deviceIDs.smartFarmID} />
        </RightHalfDiv>
      </Contents>
    </DashboardPageBlock>
  );
}

export default DashboardPage;
