import { useState, useEffect } from 'react';
import { deviceAPI } from '../iot-clientside-api';
import { DeviceProps } from '../data/deviceIDs';

type ConnectionProps = {
  name: string;
  connection: boolean;
};

function useConnections(deviceIDs: DeviceProps[]) {
  const [connected, setConnected] = useState<ConnectionProps[]>(
    deviceIDs.map(() => {
      return { name: 'loading', connection: false };
    })
  );
  const [disconnected, setDisconnected] = useState<ConnectionProps[]>(
    deviceIDs.map(() => {
      return { name: 'loading', connection: false };
    })
  );

  const promiseElement = (
    deviceID: DeviceProps,
    connections: ConnectionProps[]
  ) =>
    deviceAPI.getDeviceInfo(deviceID.id).then((resolvedData) => {
      if (resolvedData !== null) {
        const newConnection = resolvedData.connection;
        connections.push({ name: deviceID.name, connection: newConnection });
      }
    });

  const getConnections = () => {
    let promiseArr: Promise<any>[] = [];
    let tmp: ConnectionProps[] = [];
    promiseArr = deviceIDs.map((deviceID) => promiseElement(deviceID, tmp));

    Promise.all(promiseArr).then(() => {
      let tmpDisconnected: ConnectionProps[] = [];
      let tmpConnected: ConnectionProps[] = [];
      let tmpDeviceIDs = deviceIDs.map((deviceID) => deviceID.name);

      // NOTE: 중첩 forEach 괜찮은가??
      tmpDeviceIDs.forEach((deviceID) => {
        tmp.forEach((connection) => {
          if (deviceID === connection.name) {
            if (!connection.connection) {
              tmpDisconnected.push(connection);
            } else {
              tmpConnected.push(connection);
            }
          }
        });
      });

      setConnected(tmpConnected);
      setDisconnected(tmpDisconnected);
    });
  };

  useEffect(() => {
    getConnections();

    const interval = setInterval(getConnections, 1000 * 60);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  return { connected, disconnected };
}

export default useConnections;
