import IoTAPI from "./api/IoTAPI";
import { axiosGet } from "./api/wrapperAPI";

const get = (url: string) => IoTAPI.get("/smartfarmtemperaturehumidity" + url);

export const getChart = (
  smartfarmID: number | string,
  fromTime: string,
  toTime: string
) =>
  axiosGet(
    get(
      `/chart?smartFarmID=${smartfarmID}&fromTime=${fromTime}&toTime=${toTime}`
    )
  );

export const getRecent = (smartfarmID: number | string) =>
  axiosGet(get(`/recent?smartFarmID=${smartfarmID}`));
