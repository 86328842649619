import { useState, useEffect } from 'react';
import { smartFarmTemperatureHumidityAPI } from '../iot-clientside-api';

function useTemperatureHumidityRecent(smartFarmID: number) {
  type TemperatureHumidityProps = {
    time: string;
    temperature: number;
    humidity: number;
  };

  const [temperatureHumidityRecent, setTemperatureHumidityRecent] =
    useState<TemperatureHumidityProps>({
      time: 'default',
      temperature: 0,
      humidity: 0,
    });

  const getRecentData = () => {
    smartFarmTemperatureHumidityAPI
      .getRecent(smartFarmID)
      .then((resolvedData) => {
        if (resolvedData !== null) {
          setTemperatureHumidityRecent(resolvedData);
        }
      });
  };

  useEffect(() => {
    getRecentData();

    const interval = setInterval(getRecentData, 1000 * 60);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  return temperatureHumidityRecent;
}

export default useTemperatureHumidityRecent;
