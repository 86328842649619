import React, { useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import logo from "../../images/horizon-logo.png";

interface SideBarBlockProps {
  width?: number;
  height?: number;
}

const SideBarBlock = styled.div<SideBarBlockProps>`
  width: ${(props) => props.theme.screenWidth + "px"};
  height: ${(props) => props.theme.screenHeight * 0.06 + "px"};
  padding: 0;
  background: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 5px gray;
`;

const LogoImage = styled.img`
  padding-left: 1%;
  width: auto;
  height: 70%;
  cursor: pointer;
`;

const KakaoChannel = styled.div`
  margin-right: 0.5rem;
`;

interface SideBarProps {
  url?: string;
  children?: React.ReactNode;
}

function SideBar({ url = "/home", children }: SideBarProps) {
  const history = useHistory();

  return (
    <SideBarBlock>
      <LogoImage src={logo} alt="logo" />
      {children}
    </SideBarBlock>
  );
}

export default SideBar;
