import React from "react";
import styled from "styled-components";
import LoginFormComponent from "../components/login/LoginFormComponent";
import background from "../images/background.jpeg";

const LogInPageBlock = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  ${(props) => props.theme.mediaQueryMobile} {
    flex-direction: column;
  }
`;

const LeftHalfDiv = styled.div`
  width: 50%;
  height: 100%;
  ${(props) => props.theme.mediaQueryMobile} {
    display: none;
  }
`;

const RightHalfDiv = styled.div`
  width: 50%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  ${(props) => props.theme.mediaQueryMobile} {
    width: 100%;
  }
`;

const StyledImg = styled.img`
  width: 100%;
  height: 100%;
`;

const StyledDiv = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  bottom: 7%;
`;

const BottomCompanyName = styled.div`
  text-align: center;
  font-size: 0.7rem;
  padding-bottom: 1rem;
`;

function LogInPage() {
  return (
    <LogInPageBlock>
      <LeftHalfDiv>
        <StyledImg src={background} alt="background" />
      </LeftHalfDiv>
      <RightHalfDiv>
        <StyledDiv>
          <LoginFormComponent />
        </StyledDiv>
        <BottomCompanyName>Imagination Garden Inc.</BottomCompanyName>
      </RightHalfDiv>
    </LogInPageBlock>
  );
}

export default LogInPage;
