import IoTAPI from "./api/IoTAPI";
import { axiosGet, axiosPost } from "./api/wrapperAPI";

/**
 * NOTE 에어컨 프로퍼티
 *
 * power : boolean
 * ==> 에어컨 전원 ON/OFF
 *
 * temperature : number
 * ==> 에어컨 온도
 *
 * mode : number
 * ==> 냉/난방
 *
 * fanSpeed : number
 * ==> 풍속
 */

const get = (url: string) => IoTAPI.get("/RemoteControl" + url);

export const getGroupList = () => axiosGet(get("/family"));

export const getGroupDeviceList = (familyID: string | number) =>
  axiosGet(get(`/devices/${familyID}`));

export const getDeviceInfo = (deviceID: string) =>
  axiosGet(get(`/${deviceID}`));

const post = (url: string, requestBody: Object) =>
  IoTAPI.post("/RemoteControl" + url, requestBody);

export const postControlDevice = (deviceID: string, properties: Object) =>
  axiosPost(post(`/${deviceID}`, properties));
